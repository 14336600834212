import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CTA from "../components/cta"

// import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';

import poweredby_vid_mp4 from "../images/poweredby-4.mp4"
import poweredby_vid_webm from "../images/poweredby-4.webm"
import poweredby_vid_poster from "../images/poweredby-4.jpg"

import { motion } from "framer-motion"

const UkMeds = () => (
  <Layout>
    <SEO title="UK Meds - UK Based Freelance Web Designer/Developer" />
    <div className="container mx-auto px-4 max-w-7xl relative z-20">
      <div className="rounded-2xl overflow-hidden relative">
        <StaticImage className="absolute left-0 top-0 w-full h-full rounded-2xl overflow-hidden round-img z-10 work-hero" src="../images/ukmeds-thumb.jpg" alt="UKMeds" />
        <div className="w-full bg-zinc-900 bg-opacity-60 md:bg-opacity-20 bg-gradient-to-t from-zinc-900 to-transparent pt-24 md:pt-36 rounded-2xl relative z-20">
            <div className="p-8 md:p-12 lg:p-16 text-white  w-full">
                      <motion.h1 initial={{ opacity: 0, y: 15 }} whileInView={{ opacity: 1, y: 0 }} viewport={{ once: true }} className="text-5xl md:text-6xl flex flex-wrap lg:text-7xl md:max-w-sm lg:max-w-lg xl:max-w-xl font-medium leading-tight md:leading-tight lg:leading-tight tracking-tighter">UK Meds</motion.h1>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-10 lg:gap-20 xl:gap-24 pt-6 md:pt-10">
          <motion.div initial={{ opacity: 0, y: 15 }} whileInView={{ opacity: 1, y: 0, transition: { delay: 0.075 } }} viewport={{ once: true }}>
            <div className="uppercase tracking-wider text-sm md:text-base font-medium mb-3 md:mb-4">
                About the Client
            </div>
            <div className="text-base md:text-lg lg:text-xl lg:leading-normal text-white md:opacity-90 font-light">
              <p>
                A family business providing people with a 5* private healthcare service and allowing them to get medications they require delivered straight to their door
              </p>
            </div>
          </motion.div>
          <motion.div initial={{ opacity: 0, y: 15 }} whileInView={{ opacity: 1, y: 0, transition: { delay: 0.1 } }} viewport={{ once: true }}>
            <div className="uppercase tracking-wider text-sm md:text-base font-medium mb-3 md:mb-4">
                Overview of my work
            </div>
            <div className="text-base md:text-lg lg:text-xl lg:leading-normal text-white md:opacity-90 font-light">
              <ol className="space-y-2 md:space-y-3">
                <li className="flex items-start space-x-1">
                  <span>1.</span>
                  <span>UK Meds corporate company website</span>
                </li>
                <li className="flex items-start space-x-1">
                  <span>2.</span>
                  <span>Marketing website for "Powered by UK Meds"</span>
                </li>
                <li className="flex items-start space-x-1">
                  <span>3.</span>
                  <span>EU Meds website styles</span>
                </li>
              </ol>
            </div>
          </motion.div>
        </div>
            </div>
          </div>
      </div>
        
        
    </div>

    <motion.div initial={{ opacity: 0, y: 15 }} whileInView={{ opacity: 1, y: 0, transition: { delay: 0.125 } }} viewport={{ once: true }} className="relative z-20 pt-16 md:pt-24 lg:pt-36">
      <div className="container mx-auto px-4 max-w-7xl">
        <h2  className="text-3xl md:text-4xl lg:text-5xl font-medium leading-tight md:leading-tight lg:leading-tight tracking-tight text-zinc-900 mb-4 md:mb-5">
          <span className="bg-clip-text text-transparent bg-gradient-to-tr from-indigo-700 to-fuchsia-500" style={{WebkitBoxDecorationBreak : 'clone'}}>1. </span>
          Company Website
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-5 md:gap-10 lg:gap-20 xl:gap-24">
        <div className="md:col-span-8">
          <div className="text-sm md:text-base lg:text-lg text-zinc-500 space-y-3 md:space-y-4">
            <p>
              UK Meds needed a corporate "company" website, where visitors could learn more about the history of the company, as well as read the latest news.
            </p>
            <p>Using their existing brand design guidelines, I mapped out the site structure and designed all the required pages in Figma, keeping the client constantly updated. Once all the designs were approved, I started building out the website, while at the same time implementing a "headless" CMS to allow any members of their team to easily edit the site's content and add any news articles.</p>
          </div>
          
        </div>
        <div className="md:col-span-4">
        <div className="uppercase tracking-wider text-sm md:text-base font-medium mb-2 md:mb-2.5 md:mt-5">
                Services I Provided
            </div>
            <div className="text-sm md:text-base text-zinc-700 mb-5 md:mb-6">
              <p>
               Web Design, Web Development, CMS Integration
              </p>
            </div>
            <a className="flex items-center text-zinc-900 hover:underline font-medium p-4 rounded-xl bg-zinc-100 hover:bg-zinc-200 transition" href="https://company.ukmeds.co.uk" target="_blank">
              <svg className="fill-current  w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                  width="50" height="50"

                  viewBox="0 0 50 50">
                  <path d="M 37 4.0039062 C 34.69525 4.0037812 32.389766 4.8822188 30.634766 6.6367188 L 25.634766 11.636719 C 23.142766 14.128719 22.430516 17.727281 23.478516 20.863281 L 27.070312 17.271484 C 27.220312 16.244484 27.673891 15.253844 28.462891 14.464844 L 33.462891 9.4648438 C 34.437891 8.4908438 35.72 8.0019531 37 8.0019531 C 38.28 8.0019531 39.561156 8.4898437 40.535156 9.4648438 C 42.484156 11.414844 42.484156 14.586156 40.535156 16.535156 L 35.535156 21.535156 C 34.746156 22.324156 33.756516 22.776734 32.728516 22.927734 L 29.134766 26.521484 C 30.062766 26.831484 31.029047 26.996094 31.998047 26.996094 C 34.303047 26.996094 36.608281 26.118281 38.363281 24.363281 L 43.363281 19.363281 C 46.873281 15.854281 46.872281 10.145719 43.363281 6.6367188 C 41.608281 4.8827187 39.30475 4.0040313 37 4.0039062 z M 30.960938 16.980469 A 2.0002 2.0002 0 0 0 29.585938 17.585938 L 17.585938 29.585938 A 2.0002 2.0002 0 1 0 20.414062 32.414062 L 32.414062 20.414062 A 2.0002 2.0002 0 0 0 30.960938 16.980469 z M 18.449219 23.023438 C 15.997141 22.898656 13.506469 23.765766 11.636719 25.634766 L 6.6367188 30.634766 C 3.1277188 34.143766 3.1277187 39.854281 6.6367188 43.363281 C 8.3917188 45.117281 10.696 45.994141 13 45.994141 C 15.304 45.994141 17.608281 45.116328 19.363281 43.361328 L 24.363281 38.361328 C 26.855281 35.869328 27.569484 32.270766 26.521484 29.134766 L 22.927734 32.728516 C 22.777734 33.755516 22.324156 34.746156 21.535156 35.535156 L 16.535156 40.535156 C 14.586156 42.485156 11.413844 42.485156 9.4648438 40.535156 C 7.5158438 38.585156 7.5158438 35.413844 9.4648438 33.464844 L 14.464844 28.464844 C 15.253844 27.675844 16.244484 27.221312 17.271484 27.070312 L 20.863281 23.478516 C 20.079281 23.216516 19.266578 23.065031 18.449219 23.023438 z"></path>
                  </svg>
                  Visit website
            </a>
          </div>
          
        </div>
        <div className="rounded-2xl px-10 py-7 md:px-16 md:py-8 lg:px-20 lg:py-12 bg-gray-100 mt-7 md:mt-12">
          <blockquote>
            <div>
              <svg className="h-12 w-12 text-zinc-900 opacity-25" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
                <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
              </svg>
              <p className="mt-6 text-2xl md:text-3xl font-medium text-zinc-900">Excellent to work with, Donatas put across many ideas and his creativity was outstanding for the whole duration of the project. Thank you.</p>
            </div>
            <footer className="mt-6">
              <p className="text-base font-medium text-zinc-700">Oliver Soar</p>
              <p className="text-base font-regular text-zinc-500">Technical Director</p>
            </footer>
          </blockquote>
        </div>
      </div>
      
      <div className="mx-auto px-4 max-w-[1536px] pt-10 md:pt-16">
        <div className="grid grid-cols-12 gap-4 md:gap-6 lg:gap-8">
          <StaticImage className="h-72 md:h-[800px] col-span-8 lg:col-span-9 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/ukmeds-2.png" alt="UKMeds" />
          <StaticImage className="h-72 md:h-[800px] col-span-4 lg:col-span-3 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/ukmeds-1.png" alt="UKMeds" />
          <StaticImage className="h-72 md:h-[800px] col-span-12 md:col-span-4 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/ukmeds-3.png" alt="UKMeds" />
          <StaticImage className="h-72 md:h-[800px] col-span-12 md:col-span-4 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/ukmeds-7.png" alt="UKMeds" />
          <StaticImage className="h-72 md:h-[800px] col-span-12 md:col-span-4 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/ukmeds-5.png" alt="UKMeds" />
        </div>
      </div>
      
    </motion.div>
    <div className="relative z-20 pt-16 md:pt-24 lg:pt-36">
      <div className="container mx-auto px-4 max-w-7xl">
        <h2  className="text-3xl md:text-4xl lg:text-5xl font-medium leading-tight md:leading-tight lg:leading-tight tracking-tight text-zinc-900 mb-4 md:mb-5">
          <span className="bg-clip-text text-transparent bg-gradient-to-tr from-indigo-700 to-fuchsia-500" style={{WebkitBoxDecorationBreak : 'clone'}}>2. </span>
          Powered by UK Meds
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-5 md:gap-10 lg:gap-20 xl:gap-24">
        <div className="md:col-span-8">
          <div className="text-sm md:text-base lg:text-lg text-zinc-500 space-y-3 md:space-y-4">
            <p>
              UK Meds launched a new product - "Powered by UK Meds", that allows store owners to offer prescriptions to their customers. Consequently, they required a website to display the features of the product, allow new registrations, and showcase stores that are already using it.
            </p>
            <p>I used their existing brand guidelines to design the required pages in Figma, before building out a fully functional and responsive site. The "Store Locator" page required custom development to communicate with Mapbox API and display nearby stores in a list and on the map. The website code was then handed off to UK Meds developers to be implemented on their end.</p>
          </div>
          
        </div>
        <div className="md:col-span-4">
        <div className="uppercase tracking-wider text-sm md:text-base font-medium mb-2 md:mb-2.5 md:mt-5">
                Services I Provided
            </div>
            <div className="text-sm md:text-base text-zinc-700 mb-5 md:mb-6">
              <p>
               Web Design, Web Development
              </p>
            </div>
            <a className="flex items-center text-zinc-900 hover:underline font-medium p-4 rounded-xl bg-zinc-100 hover:bg-zinc-200 transition" href="https://poweredby.ukmeds.co.uk" target="_blank">
              <svg className="fill-current  w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                  width="50" height="50"

                  viewBox="0 0 50 50">
                  <path d="M 37 4.0039062 C 34.69525 4.0037812 32.389766 4.8822188 30.634766 6.6367188 L 25.634766 11.636719 C 23.142766 14.128719 22.430516 17.727281 23.478516 20.863281 L 27.070312 17.271484 C 27.220312 16.244484 27.673891 15.253844 28.462891 14.464844 L 33.462891 9.4648438 C 34.437891 8.4908438 35.72 8.0019531 37 8.0019531 C 38.28 8.0019531 39.561156 8.4898437 40.535156 9.4648438 C 42.484156 11.414844 42.484156 14.586156 40.535156 16.535156 L 35.535156 21.535156 C 34.746156 22.324156 33.756516 22.776734 32.728516 22.927734 L 29.134766 26.521484 C 30.062766 26.831484 31.029047 26.996094 31.998047 26.996094 C 34.303047 26.996094 36.608281 26.118281 38.363281 24.363281 L 43.363281 19.363281 C 46.873281 15.854281 46.872281 10.145719 43.363281 6.6367188 C 41.608281 4.8827187 39.30475 4.0040313 37 4.0039062 z M 30.960938 16.980469 A 2.0002 2.0002 0 0 0 29.585938 17.585938 L 17.585938 29.585938 A 2.0002 2.0002 0 1 0 20.414062 32.414062 L 32.414062 20.414062 A 2.0002 2.0002 0 0 0 30.960938 16.980469 z M 18.449219 23.023438 C 15.997141 22.898656 13.506469 23.765766 11.636719 25.634766 L 6.6367188 30.634766 C 3.1277188 34.143766 3.1277187 39.854281 6.6367188 43.363281 C 8.3917188 45.117281 10.696 45.994141 13 45.994141 C 15.304 45.994141 17.608281 45.116328 19.363281 43.361328 L 24.363281 38.361328 C 26.855281 35.869328 27.569484 32.270766 26.521484 29.134766 L 22.927734 32.728516 C 22.777734 33.755516 22.324156 34.746156 21.535156 35.535156 L 16.535156 40.535156 C 14.586156 42.485156 11.413844 42.485156 9.4648438 40.535156 C 7.5158438 38.585156 7.5158438 35.413844 9.4648438 33.464844 L 14.464844 28.464844 C 15.253844 27.675844 16.244484 27.221312 17.271484 27.070312 L 20.863281 23.478516 C 20.079281 23.216516 19.266578 23.065031 18.449219 23.023438 z"></path>
                  </svg>
                  Visit website
            </a>
          </div>
          
        </div>
        
      </div>
      <div className="mx-auto px-4 max-w-[1536px] pt-10 md:pt-16">
        <div className="grid grid-cols-12 gap-4 md:gap-6 lg:gap-8">
          <StaticImage className="h-72 md:h-[800px] col-span-12 lg:col-span-6 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/poweredby-1.png" alt="Powered by UK Meds" />
          <StaticImage className="h-72 md:h-[800px] col-span-8 lg:col-span-6 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/poweredby-3.png" alt="Powered by UK Meds" />
          <div className="col-span-4 lg:col-span-3 relative">
            <StaticImage className="h-72 md:h-full md:absolute rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/poweredby-2.png" alt="Powered by UK Meds" />
          </div>
          <video autoPlay muted loop poster={poweredby_vid_poster} className="h-auto col-span-12 lg:col-span-9 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm">
            <source src={poweredby_vid_webm} type="video/webm" />
            <source src={poweredby_vid_mp4} type="video/mp4" />
            
          </video>

        </div>
      </div>
      
    </div>
    <div className="relative z-20 pt-16 md:pt-24 lg:pt-36">
      <div className="container mx-auto px-4 max-w-7xl">
        <h2  className="text-3xl md:text-4xl lg:text-5xl font-medium leading-tight md:leading-tight lg:leading-tight tracking-tight text-zinc-900 mb-4 md:mb-5">
          <span className="bg-clip-text text-transparent bg-gradient-to-tr from-indigo-700 to-fuchsia-500" style={{WebkitBoxDecorationBreak : 'clone'}}>3. </span>
          EU Meds
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-5 md:gap-10 lg:gap-20 xl:gap-24">
        <div className="md:col-span-8">
          <div className="text-sm md:text-base lg:text-lg text-zinc-500 space-y-3 md:space-y-4">
            <p>
              With the expansion of their business to the EU, UK Meds wanted to modify the styles of their existing website template to use on their new EU Meds website.
            </p>
            <p>I provided them with a new colour scheme, fonts & illustrations. I handed off the project to the UK Meds development team with a full documentation of all the required changes, along with code change snippets & design previews.</p>
          </div>
          
        </div>
        <div className="md:col-span-4">
        <div className="uppercase tracking-wider text-sm md:text-base font-medium mb-2 md:mb-2.5 md:mt-5">
                Services I Provided
            </div>
            <div className="text-sm md:text-base text-zinc-700 mb-5 md:mb-6">
              <p>
               Website Styles Redesign
              </p>
            </div>
            <a className="flex items-center text-zinc-900 hover:underline font-medium p-4 rounded-xl bg-zinc-100 hover:bg-zinc-200 transition" href="https://eumeds.com" target="_blank">
              <svg className="fill-current  w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                  width="50" height="50"

                  viewBox="0 0 50 50">
                  <path d="M 37 4.0039062 C 34.69525 4.0037812 32.389766 4.8822188 30.634766 6.6367188 L 25.634766 11.636719 C 23.142766 14.128719 22.430516 17.727281 23.478516 20.863281 L 27.070312 17.271484 C 27.220312 16.244484 27.673891 15.253844 28.462891 14.464844 L 33.462891 9.4648438 C 34.437891 8.4908438 35.72 8.0019531 37 8.0019531 C 38.28 8.0019531 39.561156 8.4898437 40.535156 9.4648438 C 42.484156 11.414844 42.484156 14.586156 40.535156 16.535156 L 35.535156 21.535156 C 34.746156 22.324156 33.756516 22.776734 32.728516 22.927734 L 29.134766 26.521484 C 30.062766 26.831484 31.029047 26.996094 31.998047 26.996094 C 34.303047 26.996094 36.608281 26.118281 38.363281 24.363281 L 43.363281 19.363281 C 46.873281 15.854281 46.872281 10.145719 43.363281 6.6367188 C 41.608281 4.8827187 39.30475 4.0040313 37 4.0039062 z M 30.960938 16.980469 A 2.0002 2.0002 0 0 0 29.585938 17.585938 L 17.585938 29.585938 A 2.0002 2.0002 0 1 0 20.414062 32.414062 L 32.414062 20.414062 A 2.0002 2.0002 0 0 0 30.960938 16.980469 z M 18.449219 23.023438 C 15.997141 22.898656 13.506469 23.765766 11.636719 25.634766 L 6.6367188 30.634766 C 3.1277188 34.143766 3.1277187 39.854281 6.6367188 43.363281 C 8.3917188 45.117281 10.696 45.994141 13 45.994141 C 15.304 45.994141 17.608281 45.116328 19.363281 43.361328 L 24.363281 38.361328 C 26.855281 35.869328 27.569484 32.270766 26.521484 29.134766 L 22.927734 32.728516 C 22.777734 33.755516 22.324156 34.746156 21.535156 35.535156 L 16.535156 40.535156 C 14.586156 42.485156 11.413844 42.485156 9.4648438 40.535156 C 7.5158438 38.585156 7.5158438 35.413844 9.4648438 33.464844 L 14.464844 28.464844 C 15.253844 27.675844 16.244484 27.221312 17.271484 27.070312 L 20.863281 23.478516 C 20.079281 23.216516 19.266578 23.065031 18.449219 23.023438 z"></path>
                  </svg>
                  Visit website
            </a>
          </div>
          
        </div>
        
      </div>
      <div className="mx-auto px-4 max-w-[1536px] pt-10 md:pt-16">
        <div className="grid grid-cols-12 gap-4 md:gap-6 lg:gap-8">
          <StaticImage className="h-72 md:h-[800px] col-span-12 lg:col-span-6 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/eumeds-1.png" alt="EU Meds" />
          <StaticImage className="h-72 md:h-[800px] col-span-12 lg:col-span-6 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/eumeds-2.png" alt="EU Meds" />

        </div>
      </div>
      
    </div>
    <CTA></CTA>
  </Layout>
)

export default UkMeds
